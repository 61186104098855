@import (reference) "styles/app/app.less";

.contact-actions-list {
  .black-border;
  border-top: none;
  padding: 24px 0 0 0;

  .contact-actions-title {
    margin: 0 0 4px 8px;
  }

  .contact-actions-item {
    .black-border-top;
    .black-border-bottom;
    .pointer-cursor;
    padding: 8px;

    &:last-child {
      border-bottom: none;
    }
  }
}
