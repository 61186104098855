@import (reference) "styles/app/app.less";

.send-mails-list {
  width: 50%;
  vertical-align: top;
  display: inline-block;
  position: relative;

  .single-mail-form {
    .black-border;
    border-top: unset;
    background-color: @white;
    padding: 20px;
    position: relative;

    .button-remove-mail {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .single-mail-form-title {
      padding: 0 0 8px 8px;
    }

    textarea {
      border: none;
    }

    .eitje-field-body {
      .eitje-label {
        display: none;
      }
    }

    .eitje-field-days_till_next {
      display: inline-flex;
      margin: 12px 0 0 10px;

      .eitje-label,
      .error-msg {
        .bold;
        line-height: 2.1;
      }
    }
  }

  .button-submit-mails {
    .primary-button-mixin;
    float: right;
    margin: 20px 24px 0 0;
  }

  .multi-form-add-button {
    float: left;
    position: absolute;
    right: 108px;
  }

  .multi-form-add-button {
    .secondary-button-mixin;
    display: inline-block;
    height: 32px;
    padding: 0 12px;
    float: right;
    line-height: 2.5;
    margin: 20px 0 0 0;
    font-size: 12px;
  }
}
