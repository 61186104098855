@import (reference) "styles/app/app.less";

.contact-drawer .ant-drawer-body {
  padding: 0;

  .contact-info,
  .contact-audits {
    .black-border-bottom;
    padding: 20px;
  }

  .contact-notes {
    border-bottom: none;
    padding: 20px 20px 60px 20px;
  }

  .button-show-contact-mails {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
  }
}
