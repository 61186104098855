@import (reference) "styles/app/app.less";

table {
  .border;
  background-color: @white;
  width: 100%;

  tbody tr {
    .border-bottom;
    width: 100%;
    display: block;
  }

  thead {
    .border-bottom;
  }

  thead th,
  tbody .table-cell {
    .border-right;
    .pointer-cursor;
    .prevent-text-overflow-keep-size;
    width: 20%;
    display: inline-block;
    text-align: left;
    padding: 8px 16px;
  }
}
