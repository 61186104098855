@import (reference) '../colors/variables.less';
@import (reference) 'styles/app/styles/elements/transitions.less';

// Classes which may be made active by javascript logic.

.active-background-color {
  background-color: @hover-background-color;
}

.active-text-color {
  color: @blue;
}

// Effects, often animated.

.hover-effect {
  .transition;
  &:hover {
    .active-background-color;
  }
}

.highlight-effect {
  background-color: @white;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    .active-background-color;
  }
}

// Manipulation of basic elements.

.pointer-cursor {
  cursor: pointer;
  * {
    cursor: pointer;
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.horizontal-scroll {
  .hide-scrollbar;
  overflow-x: scroll;
}

.vertical-scroll {
  .hide-scrollbar;
  overflow-y: scroll;
}
