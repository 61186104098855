@import (reference) "styles/app/app.less";

a.modalLink {
  span {
    color: @white-text;
  }
}

button.ant-btn {
  background-color: @blue;
  border-radius: 4px;

  span {
    color: @white-text;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: @blue;
  }
}

.primary-button-mixin {
  .pointer-cursor;
  background-color: @black-text;
  border-radius: 4px;
  border: @black-text 1px solid;

  span {
    .book;
    color: @background-color;
    font-size: 12px;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: @black-text;
  }
}

button.ant-btn.poached-secondary-button {
  .secondary-button-mixin;
}

.secondary-button-mixin {
  .pointer-cursor;
  background-color: @background-color;
  border-radius: 4px;
  border: @black-text 1px solid;

  span {
    .book;
    color: @black-text;
    font-size: 12px;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: @background-color;
  }
}

button.ant-btn.poached-secondary-button {
  .secondary-button-mixin;
}
