@import (reference) "styles/app/app.less";

#received-mails {
  margin: calc(@contact-header-height - 1px) 0 0 0;
  display: inline-block;
  width: 25%;
  vertical-align: top;
  max-height: 100%;
  overflow: scroll;

  .search-container {
    .black-border;

    input {
      padding: 10px 6px;
    }
  }

  .received-mail {
    .black-border;
    border-top: none;

    display: block;
    position: relative;
    padding: 14px 6px 6px 6px;

    &.active {
      background-color: @hover-background-color;
    }

    .received-mail-contact-name {
      .prevent-text-overflow;
      display: inline-block;
      width: 78%;
    }

    .received-mail-contact-name,
    .received-mail-time {
      line-height: 1.5;
      vertical-align: top;
      font-size: 12px;
    }

    .received-mail-time {
      .prevent-text-overflow;
      text-align: right;
      position: absolute;
      right: 4px;
      display: inline-block;
      font-size: 10px;
      bottom: 6px;
    }

    .color-circle {
      .square-size(8px);
      position: absolute;
      top: 8px;
      border-radius: 100%;

      &.we-read {
        background-color: yellow;
        right: 15px;
      }

      &.we-replied {
        right: 4px;
        background-color: red;
      }
    }
  }
}
