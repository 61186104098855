@import (reference) "styles/app/app.less";

.emails {
  .simple-list {
    margin-bottom: 4px; // overwrite default to align button
  }

  .button-add-email {
    display: block;
    white-space: nowrap;
    float: right;
    margin: 0 0 12px 0;
  }

  .eitje-form {
    margin: 0 0 20px 0;
    clear: both;

    .eitje-label {
      .bold;
      margin: 0 0 4px 0;
    }

    .eitje-field-email {
      width: 75%;
      display: inline-block;
    }

    .ant-btn {
      width: calc(25% - 4px);
      margin: 0 0 0 4px;
      display: inline-block;
    }
  }
}
