@import (reference) "styles/app/app.less";

.contact-audits {
  .audit {
    .audit-header h4 {
      display: inline-block;
      width: 33%;
      margin: 20px 0 0 0;

      line-height: 2;
    }

    .audited-change {
      .black-border;
      border-bottom: none;

      &:last-child {
        .black-border-bottom;
      }

      p {
        .black-border-right;
        .prevent-text-overflow-keep-size;
        display: inline-block;

        width: 33%;
        line-height: 2;
        padding: 0 0 0 4px;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
