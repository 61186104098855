@import (reference) "styles/app/app.less";
@import "shared.less"; // only imported once, but used twice

.reply-emails-list {
  border-bottom: none;

  .simple-list {
    .black-border-bottom;
  }

  .reply-email {
    position: relative;

    p {
      .text-lowercase;
    }

    &.reply-email-active {
      background-color: @blue;
    }

    &.reply-email-blacklisted {
      background-color: @poached-red;
    }

    &.reply-email-active p,
    &.reply-email-blacklisted p {
      .bold;
      color: @white;
    }

    .reply-button {
      position: absolute;
      right: 0;
      top: 0;

      button {
        .border;
        margin: 7px 4px 0 0;
        height: 24px;
        padding: 0 16px;

        span {
          font-size: 10px;
        }
      }
    }

    &:last-child {
      .contact-actions-item {
        border-bottom: none;
      }
    }
  }
}
