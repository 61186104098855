@import (reference) "styles/app/app.less";

.start-outreach {
  .black-border-bottom;
  .black-border-top;
  padding: 20px;

  .elementContainer {
    .eitje-label {
      margin: 12px 0 6px 0;
    }

    .ant-select {
      width: 100%;
    }
  }

  .display-available-contacts {
    margin: 12px 0;
  }

  .eitje-simple-input-number {
    height: 32px;

    img,
    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  .eitje-field-amount {
    display: inline-block;
    width: calc((100% - 4px) / 2);

    .eitje-simple-input-number {
      .black-border;
      border-radius: 4px;
    }
  }

  .ant-select {
    .black-border;
    border-radius: 4px;
  }

  button {
    margin: 0 0 0 4px;
    width: calc((100% - 4px) / 2);
  }
}
