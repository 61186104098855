@import (reference) "styles/app/app.less";

#unmapped-mails {
  width: 75%;
  display: inline-block;
  margin: calc(@contact-header-height - 1px) 0 0 0;

  .map-header {
    .black-border-top;
  }
}
