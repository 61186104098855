@import (reference) '../colors/variables.less';

.big-shadow {
  box-shadow: 0px 5px 15px 0px ~'@{shadow-color}25';
}

.small-shadow {
  box-shadow: 0px 4px 8px 4px ~'@{shadow-color}25';
}

.light-shadow {
  box-shadow: 0px 4px 8px 4px ~'@{shadow-color}25';
}

.only-downwards-shadow {
  box-shadow: 0 5px 4px -2px ~'@{shadow-color}25';
}
