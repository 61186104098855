a.modalLink span {
  color: #ffffff;
}
button.ant-btn {
  background-color: #0496ff;
  border-radius: 4px;
}
button.ant-btn span {
  color: #ffffff;
}
button.ant-btn:hover,
button.ant-btn:active,
button.ant-btn:focus {
  background-color: #0496ff;
}
.primary-button-mixin {
  cursor: pointer;
  background-color: #2b2b2b;
  border-radius: 4px;
  border: #2b2b2b 1px solid;
}
.primary-button-mixin * {
  cursor: pointer;
}
.primary-button-mixin span {
  font-weight: 400;
  color: #fbfcfd;
  font-size: 12px;
}
.primary-button-mixin:hover,
.primary-button-mixin:active,
.primary-button-mixin:focus {
  background-color: #2b2b2b;
}
button.ant-btn.poached-secondary-button {
  cursor: pointer;
  background-color: #fbfcfd;
  border-radius: 4px;
  border: #2b2b2b 1px solid;
}
button.ant-btn.poached-secondary-button * {
  cursor: pointer;
}
button.ant-btn.poached-secondary-button span {
  font-weight: 400;
  color: #2b2b2b;
  font-size: 12px;
}
button.ant-btn.poached-secondary-button:hover,
button.ant-btn.poached-secondary-button:active,
button.ant-btn.poached-secondary-button:focus {
  background-color: #fbfcfd;
}
.secondary-button-mixin {
  cursor: pointer;
  background-color: #fbfcfd;
  border-radius: 4px;
  border: #2b2b2b 1px solid;
}
.secondary-button-mixin * {
  cursor: pointer;
}
.secondary-button-mixin span {
  font-weight: 400;
  color: #2b2b2b;
  font-size: 12px;
}
.secondary-button-mixin:hover,
.secondary-button-mixin:active,
.secondary-button-mixin:focus {
  background-color: #fbfcfd;
}
button.ant-btn.poached-secondary-button {
  cursor: pointer;
  background-color: #fbfcfd;
  border-radius: 4px;
  border: #2b2b2b 1px solid;
}
button.ant-btn.poached-secondary-button * {
  cursor: pointer;
}
button.ant-btn.poached-secondary-button span {
  font-weight: 400;
  color: #2b2b2b;
  font-size: 12px;
}
button.ant-btn.poached-secondary-button:hover,
button.ant-btn.poached-secondary-button:active,
button.ant-btn.poached-secondary-button:focus {
  background-color: #fbfcfd;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-lowercase:first-letter {
  text-transform: lowercase;
}
.text-capitalize {
  text-transform: lowercase;
}
.text-capitalize:first-letter {
  text-transform: capitalize;
}
.unset-text-transform {
  text-transform: unset;
}
.unset-text-transform:first-letter {
  text-transform: unset;
}
.bold {
  font-weight: 600;
}
.book {
  font-weight: 400;
}
.decreased-line-height {
  line-height: 1;
}
.default-line-height {
  line-height: 1.4;
}
.increased-line-height {
  line-height: 1.5;
}
.red {
  color: #ff0020;
}
.white {
  color: #ffffff;
}
.blue {
  color: #0496ff;
}
.capitalize {
  text-transform: capitalize;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.base-typography {
  font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #2b2b2b;
  line-height: 1.4;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  font-weight: 400;
}
.base-typography:first-letter {
  text-transform: uppercase;
}
h1 {
  font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #2b2b2b;
  line-height: 1.4;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  font-weight: 400;
  font-weight: 600;
  font-size: 40px;
  line-height: 1;
}
h1:first-letter {
  text-transform: uppercase;
}
h2 {
  font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #2b2b2b;
  line-height: 1.4;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 24px;
  font-weight: 600;
}
h2:first-letter {
  text-transform: uppercase;
}
h3 {
  font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #2b2b2b;
  line-height: 1.4;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 18px;
  font-weight: 600;
}
h3:first-letter {
  text-transform: uppercase;
}
h4 {
  font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #2b2b2b;
  line-height: 1.4;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  font-weight: 400;
  font-weight: 600;
  font-size: 14px;
}
h4:first-letter {
  text-transform: uppercase;
}
p {
  font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #2b2b2b;
  line-height: 1.4;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
}
p:first-letter {
  text-transform: uppercase;
}
span {
  font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #2b2b2b;
  line-height: 1.4;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
}
span:first-letter {
  text-transform: uppercase;
}
h5 {
  font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #2b2b2b;
  line-height: 1.4;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 12px;
  color: #828282;
}
h5:first-letter {
  text-transform: uppercase;
}
h6 {
  font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #2b2b2b;
  line-height: 1.4;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 10px;
}
h6:first-letter {
  text-transform: uppercase;
}
div:first-letter {
  text-transform: uppercase;
}
a {
  cursor: pointer;
  color: #0496ff;
}
a * {
  cursor: pointer;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fbfcfd;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'system-ui', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
thead,
tbody th,
tbody td {
  background-color: #ffffff;
}
.prevent-text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.prevent-text-overflow-keep-size {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
}
.border-bottom {
  border-bottom-color: #eeeeee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.border-right {
  border-right-color: #eeeeee;
  border-right-width: 1px;
  border-right-style: solid;
}
.border-left {
  border-left-color: #eeeeee;
  border-left-width: 1px;
  border-left-style: solid;
}
.border-top {
  border-top-color: #eeeeee;
  border-top-width: 1px;
  border-top-style: solid;
}
.border {
  border-color: #eeeeee;
  border-width: 1px;
  border-style: solid;
}
.black-border-bottom {
  border-bottom-color: #000000;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.black-border-right {
  border-right-color: #000000;
  border-right-width: 1px;
  border-right-style: solid;
}
.black-border-left {
  border-left-color: #000000;
  border-left-width: 1px;
  border-left-style: solid;
}
.black-border-top {
  border-top-color: #000000;
  border-top-width: 1px;
  border-top-style: solid;
}
.black-border {
  border-color: #000000;
  border-width: 1px;
  border-style: solid;
}
.dashed-border {
  border-color: #eeeeee;
  border-width: 1px;
  border-style: solid;
  border-width: 4px;
  border-style: dashed;
}
.border-radius {
  border-radius: 4px;
}
.large-border-radius {
  border-radius: 8px;
}
.big-shadow {
  box-shadow: 0px 5px 15px 0px #aaaaaa25;
}
.small-shadow {
  box-shadow: 0px 4px 8px 4px #aaaaaa25;
}
.light-shadow {
  box-shadow: 0px 4px 8px 4px #aaaaaa25;
}
.only-downwards-shadow {
  box-shadow: 0 5px 4px -2px #aaaaaa25;
}
.rotate-letter-x-as-cross {
  transform: rotate(45deg);
}
.active-background-color {
  background-color: #e8f5ff;
}
.active-text-color {
  color: #0496ff;
}
.hover-effect {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hover-effect:hover {
  background-color: #e8f5ff;
}
.highlight-effect {
  background-color: #ffffff;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.highlight-effect:hover {
  background-color: #e8f5ff;
}
.pointer-cursor {
  cursor: pointer;
}
.pointer-cursor * {
  cursor: pointer;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.horizontal-scroll {
  overflow-x: scroll;
}
.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
.vertical-scroll {
  overflow-y: scroll;
}
.vertical-scroll::-webkit-scrollbar {
  display: none;
}
.transition {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.transistion-quick {
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.transition-none {
  transition-property: none;
  transition-duration: 0s;
  transition-delay: 0s;
}
.button-icon {
  width: 10px;
  height: 10px;
  margin: 0 8px 0 0;
}
.button-icon-small {
  width: 8px;
  height: 8px;
  margin: 0 8px 0 0;
}
.row {
  display: flex;
  align-items: center;
  width: 100%;
}
.column {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.debug {
  background-color: red !important;
  color: yellow !important;
  text-decoration: underline !important;
  font-weight: 800 !important;
}
.debug h1,
.debug h2,
.debug h3,
.debug h4,
.debug h5,
.debug h6,
.debug p,
.debug span {
  color: yellow !important;
  text-decoration: underline !important;
  font-weight: 800 !important;
}
.debug2 {
  color: green !important;
  background-color: blue !important;
  font-weight: 800;
}
.debug-hide {
  display: none !important;
  visibility: hidden !important;
  height: 0px !important;
  width: 0px !important;
}
