@import (reference) "styles/app/app.less";
@import (reference)
  "/src/cores/mail/inbox/pages/index/contact_mails/styles/mails.less";

// Layout styling of the page
.send-page-mixin {
  .send-page-content {
    margin-top: @contact-header-height;
  }

  .conversation-list {
    width: 25%;
    z-index: 200;
    background-color: @white;
  }

  .send-mails-list {
    .vertical-scroll;
    margin-left: -1px; // prevent collapsing border
    max-height: @send-page-body-height;
    padding: 0 0 30px 0;

    .multi-form-add-button {
      margin: 20px 0 0 0;
    }
  }

  .template-column {
    .black-border;
    border-top: unset;
    margin-left: -2px; // prevent collapsing border

    display: inline-block;
    width: calc(25% + 2px);
    vertical-align: top;

    .single-mails,
    .snippets,
    .simple-grouped-list {
      width: 100%;
    }
  }
}

// Specific adjustment styling of the page

.send-page-mixin {
  .contact-email {
    .text-lowercase;
    display: inline-block;
  }
}
