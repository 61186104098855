.prevent-text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.prevent-text-overflow-keep-size {
  // wicked shit... read: https://stackoverflow.com/a/25818809/15048016
  .prevent-text-overflow;
  vertical-align: bottom;
}
