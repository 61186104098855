@import (reference) "styles/app/app.less";

.follow-ups-page {
  .simple-grouped-list {
    width: 100%;
    margin: 20px 0 0 0;
    border: none;
    background-color: unset;
  }
}
