@import (reference) "styles/app/app.less";

.login-page {
  .black-border;
  width: 300px;
  margin-top: 120px;
  margin-left: calc((100% - 300px) / 2);
  padding: 20px 20px 50px 20px;

  .eitje-form {
    margin: 12px 0 0 0;

    .elementContainer {
      margin: 0 0 12px 0;
    }

    button {
      float: right;
    }
  }
}
