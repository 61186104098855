@import (reference) "styles/app/app.less";

.contact-notes {
  .note {
    .note-timestamp {
      margin: 12px 0 4px 0;
    }

    .note-body {
      .black-border;
      line-height: 1.5;
      padding: 8px 4px;
    }
  }

  .button-add-note {
    float: right;
  }

  .eitje-form {
    margin: 20px 0 40px 0;
    clear: both;

    .eitje-label {
      .bold;
    }

    .eitje-field-note {
      margin: 0 0 4px 0;
    }

    button {
      float: right;
    }
  }
}
