@import (reference) "styles/app/app.less";

#follow-ups {
  .simple-list a {
    .follow-up-container {
      .black-border-bottom;
      display: flex;
      padding: 4px 8px 4px 2px;
      background-color: @hover-background-color;
      overflow: scroll;
      width: 100%;
    }

    .follow-up-text {
      line-height: 1;
      font-size: 12px;
      padding: 10px 0 0 0;
    }

    .follow-up-button {
      margin: 0 2px 0 0;
      padding: 4px;
      span {
        font-size: 10px;
      }
    }

    &:last-child .follow-up {
      border-bottom: none;
    }
  }
}
