@import (reference) "styles/app/app.less";

.body {
  width: 100%;
  margin: @body-start 0 0 0;
  padding: @body-padding @body-padding 0 @body-padding;
}

body {
  height: calc(100vh - @body-start);
  overflow: scroll;
}

.button-logout {
  position: fixed;
  top: 14px;
  left: 30px;
  z-index: 10;
}
