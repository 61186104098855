@import (reference) "styles/app/app.less";

.sequence-info {
  .eitje-field-subject {
    .black-border-top;
    padding: 20px;

    .eitje-label {
      .bold;
    }

    input {
      .black-border;
    }
  }
}
