@import (reference) "styles/app/app.less";

.map-header {
  .black-border;
  display: inline-flex;
  justify-content: space-between;
  border-top: none;
  width: 66%;
  padding: 5px 12px;
  margin-right: -2px; // prevent collapsing border

  .map-header-status {
    .bold;
    line-height: 2.2;
    color: @white-text;
  }

  &.map-header-no_map {
    background-color: @poached-red;
  }

  &.map-header-manual,
  &.map-header-auto_email {
    background-color: @poached-green;
  }

  &.map-header-auto_domain,
  &.map-header-auto_exordium {
    background-color: @poached-orange;
  }
}
