@import (reference) "styles/app/app.less";

//////////////
// Page
/////////////

.auto-mail-page {
  .page-title {
    margin: 0 0 24px 0;
    text-align: center;
  }

  .eitje-confirm-button {
    margin-left: calc(50% - 50px);
    width: 100px;
  }
}

//////////////
// item
/////////////

.auto-mail-page .auto-mail-item {
  .black-border;
  .row;
  margin: 0 0 24px calc((100% - 1200px) / 2);
  width: 1200px;

  .auto-mail-item-left,
  .auto-mail-item-right {
    width: 50%;
    padding: 24px;
  }

  .auto-mail-item-left {
    .black-border-right;
  }

  .auto-mail-item-right {
    p {
      white-space: break-spaces;
    }

    .highlight-text {
      .debug;
    }
  }

  &.ignored {
    background-color: #ff000030;
  }

  .ant-switch {
    margin: 18px 24px 0 0;
  }

  .info-button {
    float: right;
    margin: 12px 0 0 0;
  }
}
