@import (reference) "styles/app/app.less";

// General layout styling

.settings-page-mixin {
  .eitje-form {
    // .black-border;
    width: 70%;
    display: inline-block;
    vertical-align: top;
  }

  .simple-list,
  .simple-grouped-list {
    // .black-border;
    margin: 58px 0 0 0;
    width: 30%;
    display: inline-block;
    vertical-align: top;
  }
}

// Specific styling

.settings-page-mixin {
  .eitje-form {
    padding: 20px;

    .eitje-label {
      .base-typography;
      font-size: 18px;
      font-weight: 600;
      margin: 0 0 12px 0;
    }

    .eitje-input {
      .black-border;
      padding: 20px;

      .add-icon {
        display: none;
      }
    }
  }

  .simple-list {
    .search-container {
      .black-border;
    }

    .settings-page-list-item {
      .black-border;
      border-top: none;
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      padding: 4px 12px 0px 12px;
    }

    .settings-page-list-item,
    p {
      .prevent-text-overflow;
      .text-lowercase;
      line-height: 2.5;
    }
  }
}
