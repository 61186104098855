@import (reference) "styles/app/app.less";

// Styling all columns

.edit-sequence-page-mixin {
  .sequence-info,
  .send-mails-list,
  .placeholders {
    .vertical-scroll;
    max-height: calc(100vh - @body-start - @body-padding);
  }

  .form-send-test {
    padding: 20px;

    input {
      .black-border;
    }
    button {
      width: 100%;
      margin: 4px 0 0 0;
    }
  }
}

// Styling of left info column

.edit-sequence-page-mixin {
  .sequence-info {
    .black-border;
    display: inline-block;
    width: 20%;
    background-color: @white;

    .sequence-name {
      padding: 20px;

      .eitje-label {
        display: none;
      }

      input {
        .black-border;
      }
    }

    .sequence-label,
    .action-label {
      .black-border-top;
      .black-border-bottom;
      padding: 20px;

      .eitje-label {
        .bold;
      }

      .ant-select {
        .black-border;
        border-radius: 4px;
      }
    }

    .action-label {
      border-top: unset;

      .ant-select {
        width: 100%;
      }
    }

    .title-sequence-type {
      margin: 4px 0 10px 0;
    }

    .eitje-label {
      margin: 6px 0;
    }

    .eitje-field-label .ant-select {
      width: 100%;
    }
  }
}

.label-dropdown-contents {
  .border-top;
  padding: 12px;

  input {
    .black-border;
  }

  button {
    width: 100%;
    margin: 4px 0 0 0;
  }
}

// Styling of middle template mails column

.edit-sequence-page-mixin {
  .send-mails-list {
    width: 60%;
    margin-left: -1px;
    padding: 0 0 20px 0;

    .single-mail-form {
      .black-border;
      margin-top: -1px;
    }

    .eitje-form:first-child .single-mail-form {
      margin-top: 0px;
    }
  }
}

// Styling of right placeholders column

.edit-sequence-page-mixin {
  .placeholders {
    .black-border;
    background-color: @white;
    width: 20%;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 -1px;

    .simple-list {
      margin: 0;
    }

    .placeholders-title {
      padding: 8px 0 4px 8px;
    }

    .placeholder-item {
      .black-border-bottom;
      .pointer-cursor;
      .prevent-text-overflow;

      &:last-child {
        border-bottom: unset;
      }

      .placeholder-item-content {
        line-height: 2;
        padding: 0 0 0 8px;
      }
    }
  }
}
