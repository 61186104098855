@import (reference) "styles/app/app.less";

.conversation-list {
  .vertical-scroll;
  background-color: @white;
  width: 66%;
  display: inline-block;
  @extra: 50px;
  max-height: calc(@send-page-body-height - @extra);
  margin-right: -1px; // prevent collapsing border

  .simple-list {
    margin: 0;
  }

  .mail {
    .black-border;
    white-space: break-spaces;
    border-top: none;
    padding: 12px;
    position: relative;
    overflow: hidden;
    margin-left: -1px;

    h5 {
      .prevent-text-overflow;
    }

    .label {
      .book;
      .black-border;
      position: absolute;
      right: 12px;
      top: 12px;
      padding: 2px 4px;
      color: @white-text;
      border-radius: 3px;
      background-color: @white !important;
      color: @black-text;

      &.planned {
        .bold;
        background-color: @orange !important;
        color: @white-text;
      }
    }

    .mail-body {
      margin: 12px 0 6px 0;
    }
  }
}
