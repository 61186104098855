@import (reference) "styles/app/app.less";

.cleanse-contacts {
  @column-width: 33%;

  .cleans-contacts-count {
    text-align: right;
    margin: 0 0 4px 0;
  }

  .cleanse-contacts-header {
    .border;
    .border-radius;
    background-color: @white;

    p {
      .border;
      .bold;
      width: @column-width;
      display: inline-block;
      line-height: 32px;
      padding: 0 24px;
    }
  }

  .cleanse-form {
    padding: 0 0 84px 0;

    .eitje-form {
      .border-bottom;

      .elementContainer,
      .non-cleansed-name,
      .contact-url {
        width: @column-width;
        display: inline-block;
        vertical-align: top;
        line-height: 40px;
        padding: 4px 24px;
      }

      .eitje-label {
        display: none;
      }
    }

    .cleanse-button {
      .secondary-button-mixin;
      float: right;
      margin: 24px 0;
    }
  }
}
