@import (reference) "styles/app/app.less";

/////////////////////////////////////
// Left side of the snippets page
/////////////////////////////////////

.snippets-page {
  .eitje-field-name,
  .eitje-field-label {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .eitje-field-name {
    padding: 0 20px 0 0;
  }

  .eitje-field-label {
    .ant-select {
      .black-border;
      width: 100%;
      padding: 14px;
      background-color: @white;

      .ant-select-selector {
        border: none;
      }
    }
  }

  .eitje-field-body {
    margin: 20px 0 0 0;

    textarea {
      height: 300px;
    }
  }

  .button-submit-snippet {
    float: right;
    margin: 20px 0 0 0;
  }
}

/////////////////////////////////////
// Right side of the snippets page
/////////////////////////////////////

.snippets-page .simple-grouped-list {
  border-bottom: none;

  .grouped-list-group-title {
    padding: 8px 12px;
  }

  .simple-list {
    width: 100%;
    margin: 0;

    .settings-page-list-item {
      .pointer-cursor;
      padding: 8px 12px;
      border-left: none;
    }

    .snippet-title {
      border-bottom: none;
    }
  }
}
