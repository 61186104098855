@import (reference) "styles/app/app.less";

.conversation-drawer {
  .ant-drawer-header {
    position: absolute;
  }

  .button-cancel-planned-mails {
    position: absolute;
    right: 12px;
    top: 20px;
  }

  .ant-drawer-body {
    padding: 30px 0 0 0;

    .conversation-list {
      width: 100%;
      max-height: 100%;
      padding: 20px 0 0 0;

      .simple-list {
        margin: 0; // overwrite default

        .mail {
          padding: 30px 20px;
        }
      }
    }
  }
}
