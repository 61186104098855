@import (reference) "styles/app/app.less";

@map-header-height: 44px;

.contact-actions {
  .vertical-scroll;
  background-color: @white;
  max-height: calc(100vh - 150px);
  display: inline-block;
  width: calc(34% + 1px);
  vertical-align: top;
  margin: -@map-header-height 0 0 0;
}
