@import (reference) "styles/app/app.less";

.simple-grouped-list {
  .black-border;
  background-color: @white;
  vertical-align: top;
  display: inline-block;
  width: 20%;

  .grouped-list-group {
    .grouped-list-group-title {
      .black-border-bottom;
      .pointer-cursor;
      line-height: 2;
      padding: 0px 8px;
    }

    .simple-list {
      p {
        .black-border-bottom;
        .pointer-cursor;
        .prevent-text-overflow;
        line-height: 2;
        padding: 0px 8px 0px 18px;
      }
    }
  }
}

// Resides outside of the component, ideally change
.grouped-list-title {
  padding: 8px;
}
