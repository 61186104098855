@import (reference) "styles/app/app.less";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: @background-color;
  .black-border-bottom;

  .ant-spin {
    margin: 20px 0 0 120px;

    .ant-spin-dot-item {
      background-color: @red;
    }
  }

  .header-items {
    float: right;
    padding: 0 20px 0 0;

    .header-item {
      .pointer-cursor;
      display: inline-block;
      margin: 20px 12px;
    }
  }
}
