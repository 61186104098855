@import (reference) "styles/app/app.less";

.remap-modal {
  .remap-modal-header {
    .black-border-bottom;
    .row;
    justify-content: space-between;
    padding: 0px 20px 8px 20px;

    h3,
    .elementContainer,
    .remap-modal-header-right {
      display: inline-block;
    }

    .remap-modal-header-right {
      button {
        margin-left: 4px;
      }
    }
  }

  .remap-modal-column {
    .black-border-right;
    .vertical-scroll;
    display: inline-block;
    padding: 20px;
    width: 50%;
    vertical-align: top;
    height: 800px;

    &:last-child {
      border-right: none;
    }
  }
}
