@import (reference) "styles/app/app.less";

.data-page {
  .data-menu {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    z-index: 10;
    width: 200px;
    right: 30px;
    background-color: @white;

    .data-header-item {
      .black-border;
      border-top: none;
      display: block;
      padding: 8px 16px;
    }

    &:first-child {
      .black-border-top;
    }
  }

  .data-charts {
    width: 100%;
    display: inline-block;
  }
}

// width={1600}
// height={800}
