@import (reference) "styles/app/app.less";

// Layout styling of follow ups

@follow-up-table-first-column-width: 20%;
@follow-up-table-column-width: 10%;

.follow-ups-page {
  thead th,
  tbody .table-cell {
    width: @follow-up-table-column-width;

    &:first-child {
      width: @follow-up-table-first-column-width;
    }
  }

  th.table-cell,
  td.table-cell {
    color: @black-text;
  }
}

// Specific styling of follow ups

.follow-ups-page {
  .follow-up-tables-list {
    .follow-up-group {
      margin: 0 0 28px 0;

      .follow-up-group-title {
        margin: 0 0 8px 16px;
      }

      .table-cell b,
      .headers {
        font-weight: 400;
      }
    }
  }
}
