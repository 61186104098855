@import (reference) "styles/app/app.less";

.simple-table-header {
  margin: 12px 0;
}

.simple-table {
  .black-border;
  margin: 0 0 20px 0;

  .simple-cell {
    .black-border-bottom;

    &:last-child {
      border-bottom: none;
    }

    .object-key,
    .object-value {
      display: inline-block;
      width: 50%;
      line-height: 2;
      padding: 0px 8px;
    }

    .object-key {
      .black-border-right;
    }
  }
}
