.text-lowercase {
  text-transform: lowercase;
  &:first-letter {
    text-transform: lowercase;
  }
}

.text-capitalize {
  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize;
  }
}

.unset-text-transform {
  text-transform: unset;
  &:first-letter {
    text-transform: unset;
  }
}
