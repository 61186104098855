// Border radius variables.

@small-border-radius: 4px;
@large-border-radius: 8px;

// Default, simple borders.

.border-bottom {
  border-bottom-color: @border-grey;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.border-right {
  border-right-color: @border-grey;
  border-right-width: 1px;
  border-right-style: solid;
}

.border-left {
  border-left-color: @border-grey;
  border-left-width: 1px;
  border-left-style: solid;
}

.border-top {
  border-top-color: @border-grey;
  border-top-width: 1px;
  border-top-style: solid;
}

.border {
  border-color: @border-grey;
  border-width: 1px;
  border-style: solid;
}

// Default, black borders.

.black-border-bottom {
  border-bottom-color: @black;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.black-border-right {
  border-right-color: @black;
  border-right-width: 1px;
  border-right-style: solid;
}

.black-border-left {
  border-left-color: @black;
  border-left-width: 1px;
  border-left-style: solid;
}

.black-border-top {
  border-top-color: @black;
  border-top-width: 1px;
  border-top-style: solid;
}

.black-border {
  border-color: @black;
  border-width: 1px;
  border-style: solid;
}

// Custom border styles.

.custom-border(@radius, @color) {
  .border;
  border-radius: @radius;
  border-color: @color;
}

.dashed-border {
  .border;
  border-width: 4px;
  border-style: dashed;
}

// Radius styles.

.border-radius {
  border-radius: @small-border-radius;
}

.large-border-radius {
  border-radius: @large-border-radius;
}
