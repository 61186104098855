@import (reference) "styles/app/app.less";

.crm-table-filter {
  .row;
  gap: 8px;
  margin: 0 0 20px 0;

  .eitje-field-filter_column,
  .eitje-field-filter_query {
    display: inline-block;

    .eitje-label {
      display: none;
    }
  }
}
