@import (reference) "styles/app/app.less";

.pause-sequence {
  .black-border-top;
  padding: 20px;

  .sequence-status-text {
    margin: 0 0 12px 0;
  }

  .button-pause {
    width: 100%;
  }
}
