@import (reference) "styles/app/app.less";

@sequence-table-first-column-width: 19%;
@sequence-table-column-width: 9%;

.sequence-table,
.campaign-table {
  thead th,
  tbody .table-cell {
    width: @sequence-table-column-width;

    &:first-child {
      width: @sequence-table-first-column-width;
    }
  }

  th.table-cell,
  td.table-cell {
    color: @black-text;
  }

  tr {
    .pointer-cursor;
    font-size: 12px;

    p {
      font-size: 12px;
    }

    button {
      .secondary-button-mixin;
      height: 24px;
      padding: 0 12px;

      span {
        .text-lowercase;
        font-size: 10px;
      }
    }
  }
}

.sequence-table {
  margin: 20px 0 0 0;
}

.campaign-table {
  margin: -1px 0 0 0; // -1px to prevent double border

  .table-row {
    .table-cell:first-child {
      .book;
      padding-left: 40px;
    }
  }
}
