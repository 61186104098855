.button-icon {
  width: 10px;
  height: 10px;
  margin: 0 8px 0 0;
}

.button-icon-small {
  width: 8px;
  height: 8px;
  margin: 0 8px 0 0;
}
