@import (reference) "styles/app/app.less";

.contact-header {
  .black-border;
  position: fixed;
  width: calc(100% - 2 * @body-padding);
  top: calc(@body-start + @body-padding);
  left: @body-padding;
  background-color: @white;
  padding: 12px;
  z-index: 10;

  .contact-info,
  .contact-name,
  .eitje-form,
  .contact-header-buttons,
  .contact-status {
    display: inline-block;
  }

  .contact-header-buttons {
    float: right;
  }

  .contact-info .contact-name {
    margin: 0 4px 0 0;
  }

  .contact-info {
    width: 25%;
  }

  .eitje-form {
    display: inline-flex;
    width: 50%;
    gap: 4px;

    .eitje-field-first_name {
      display: inline-block;
    }
  }

  .contact-header-buttons {
    width: 25%;
    display: inline-flex;
    gap: 4px;
    flex-direction: row-reverse;
  }
}
