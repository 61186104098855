@import (reference) "styles/app/app.less";

.simple-list-header {
  margin: 12px 0;
  clear: both;
}

.simple-list {
  margin: 0 0 20px 0;
  background-color: @white;

  .simple-list-item {
    .black-border;
    border-bottom: none;

    &:last-child {
      .black-border-bottom;
    }

    .simple-list-item-content {
      .text-lowercase;
      line-height: 2;
      padding: 0px 8px;
    }
  }
}
